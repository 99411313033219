import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _ac76bd0e = () => interopDefault(import('../pages/bugsnag.vue' /* webpackChunkName: "pages/bugsnag" */))
const _e4f0c118 = () => interopDefault(import('../pages/download.vue' /* webpackChunkName: "pages/download" */))
const _2e648a0a = () => interopDefault(import('../pages/instaredirect.vue' /* webpackChunkName: "pages/instaredirect" */))
const _e86750de = () => interopDefault(import('../pages/user/login.vue' /* webpackChunkName: "pages/user/login" */))
const _7bb4bd3c = () => interopDefault(import('../pages/webview/cancel.vue' /* webpackChunkName: "pages/webview/cancel" */))
const _13a9b660 = () => interopDefault(import('../pages/webview/fail.vue' /* webpackChunkName: "pages/webview/fail" */))
const _252ec768 = () => interopDefault(import('../pages/webview/logout.vue' /* webpackChunkName: "pages/webview/logout" */))
const _9aab4828 = () => interopDefault(import('../pages/webview/openGiftcard.vue' /* webpackChunkName: "pages/webview/openGiftcard" */))
const _2daf09c4 = () => interopDefault(import('../pages/webview/openSignup.vue' /* webpackChunkName: "pages/webview/openSignup" */))
const _6fa5e540 = () => interopDefault(import('../pages/webview/openSuspend.vue' /* webpackChunkName: "pages/webview/openSuspend" */))
const _106e5a95 = () => interopDefault(import('../pages/webview/recaptcha.vue' /* webpackChunkName: "pages/webview/recaptcha" */))
const _0f97b531 = () => interopDefault(import('../pages/webview/success.vue' /* webpackChunkName: "pages/webview/success" */))
const _7957a706 = () => interopDefault(import('../pages/plan/suspend/retain-done.vue' /* webpackChunkName: "pages/plan/suspend/retain-done" */))
const _37a45d92 = () => interopDefault(import('../pages/warning/external/generic.vue' /* webpackChunkName: "pages/warning/external/generic" */))
const _45d6d372 = () => interopDefault(import('../pages/webview/partials/account.vue' /* webpackChunkName: "pages/webview/partials/account" */))
const _36b6e16a = () => interopDefault(import('../pages/webview/partials/giftcard.vue' /* webpackChunkName: "pages/webview/partials/giftcard" */))
const _26e6e654 = () => interopDefault(import('../pages/webview/partials/invite.vue' /* webpackChunkName: "pages/webview/partials/invite" */))
const _1ccdb4ee = () => interopDefault(import('../pages/webview/partials/profile.vue' /* webpackChunkName: "pages/webview/partials/profile" */))
const _5566254d = () => interopDefault(import('../pages/webview/partials/qrjockey.vue' /* webpackChunkName: "pages/webview/partials/qrjockey" */))
const _7de6ee3a = () => interopDefault(import('../pages/webview/partials/signup.vue' /* webpackChunkName: "pages/webview/partials/signup" */))
const _116a1272 = () => interopDefault(import('../pages/plan/suspend/retain/coupon-offer.vue' /* webpackChunkName: "pages/plan/suspend/retain/coupon-offer" */))
const _51051e67 = () => interopDefault(import('../pages/plan/suspend/retain/coupon-remember.vue' /* webpackChunkName: "pages/plan/suspend/retain/coupon-remember" */))
const _7e0f4f97 = () => interopDefault(import('../pages/plan/suspend/retain/let-go.vue' /* webpackChunkName: "pages/plan/suspend/retain/let-go" */))
const _e5f70ad4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/bugsnag",
    component: _ac76bd0e,
    name: "bugsnag"
  }, {
    path: "/download",
    component: _e4f0c118,
    name: "download"
  }, {
    path: "/instaredirect",
    component: _2e648a0a,
    name: "instaredirect"
  }, {
    path: "/user/login",
    component: _e86750de,
    name: "user-login"
  }, {
    path: "/webview/cancel",
    component: _7bb4bd3c,
    name: "webview-cancel"
  }, {
    path: "/webview/fail",
    component: _13a9b660,
    name: "webview-fail"
  }, {
    path: "/webview/logout",
    component: _252ec768,
    name: "webview-logout"
  }, {
    path: "/webview/openGiftcard",
    component: _9aab4828,
    name: "webview-openGiftcard"
  }, {
    path: "/webview/openSignup",
    component: _2daf09c4,
    name: "webview-openSignup"
  }, {
    path: "/webview/openSuspend",
    component: _6fa5e540,
    name: "webview-openSuspend"
  }, {
    path: "/webview/recaptcha",
    component: _106e5a95,
    name: "webview-recaptcha"
  }, {
    path: "/webview/success",
    component: _0f97b531,
    name: "webview-success"
  }, {
    path: "/plan/suspend/retain-done",
    component: _7957a706,
    name: "plan-suspend-retain-done"
  }, {
    path: "/warning/external/generic",
    component: _37a45d92,
    name: "warning-external-generic"
  }, {
    path: "/webview/partials/account",
    component: _45d6d372,
    name: "webview-partials-account"
  }, {
    path: "/webview/partials/giftcard",
    component: _36b6e16a,
    name: "webview-partials-giftcard"
  }, {
    path: "/webview/partials/invite",
    component: _26e6e654,
    name: "webview-partials-invite"
  }, {
    path: "/webview/partials/profile",
    component: _1ccdb4ee,
    name: "webview-partials-profile"
  }, {
    path: "/webview/partials/qrjockey",
    component: _5566254d,
    name: "webview-partials-qrjockey"
  }, {
    path: "/webview/partials/signup",
    component: _7de6ee3a,
    name: "webview-partials-signup"
  }, {
    path: "/plan/suspend/retain/coupon-offer",
    component: _116a1272,
    name: "plan-suspend-retain-coupon-offer"
  }, {
    path: "/plan/suspend/retain/coupon-remember",
    component: _51051e67,
    name: "plan-suspend-retain-coupon-remember"
  }, {
    path: "/plan/suspend/retain/let-go",
    component: _7e0f4f97,
    name: "plan-suspend-retain-let-go"
  }, {
    path: "/",
    component: _e5f70ad4,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
