import Axios from "axios";
import {update} from "iugu/lib/IuguMethod.basic";

export const state = () => ({
    "userAuth": null,
    "id": null,
    "bearer": null,
    "dismissAppInfo": false,
    "name": null,
    "email": null,
    "cpf": null,
    "avatar": null,
    "is_member": false,
    "is_plan_active": false,
    "can_switch_plan": false,
    "has_instagram": false,
    "has_facebook": false,
    "plan": {},
    "promo_coupon": {},
    "address": null,
    "created_at": {},
    "payment": null,
    "raffleDate": {},
    "nextRaffleDate": {},
    "couponVouchers": false,
    "vouchers": false,
    "suspend_retain_link": null,
    "hasAcceptedAgreement": false,
    "visualIdentityModifier": false,
    "user_main_agreement": null
});

export const actions = {
    checkUser({ state, dispatch, commit }, payload) {
        Axios.post("/checkForUser", payload)
            .then(response => {
                if (response.data.success) {
                    let userDataArr = Object.entries(response.data.data);
                    userDataArr.forEach((element) => {
                        let payload = {};
                        if (element[0] === "user_agreements" && element[1].length > 0) {
                            let array = element[1];
                            let ua = array[0];
                            array.forEach((element) => {
                                if (element.status === 'accepted') {
                                    ua = element;
                                    commit('updateField', {field: 'hasAcceptedAgreement', value: true});
                                }
                            })
                            payload = {
                                field: element[0],
                                value: ua
                            };
                        } else {
                            payload = {
                                field: element[0],
                                value: element[1]
                            };
                        }
                        commit('updateField', payload);
                    });
                    if(response.data.data.user_agreements.length > 0) {
                        dispatch('setUserAgreementData', response.data.data.user_agreements);
                    } else {
                        commit('setUserMainAgreement', {})
                    }
                    if (
                        state.address &&
                        state.address.address
                    ) {
                        dispatch('uiControl/setJourneyAddress', state.address, { root: true });
                    }
                    this.$api.setToken(response.data.data.bearer)
                    dispatch('loggedInSucessfully');
                    dispatch('uiControl/logInRoutines', {}, { root: true });

                    let planType;
                    if (!state.is_member || state.plan.photos === 0) { planType = 'grátis'}
                    else if (state.plan.config.loyalty) { planType = 'ANUAL' }
                    else if (state.is_plan_active) { planType = 'MENSAL' }
                    else { planType = 'mensal suspenso' }

                    if (process.client) {
                        this.$globalFuncs.firebaseProxy('id', state.id);
                        this.$globalFuncs.firebaseProxy('props', {
                            plano: state.plan.id,
                            frete: state.plan.shipment_price,
                            instagram: (state.has_instagram)?'ativo':'inativo',
                            facebook: (state.has_facebook)?'ativo':'inativo',
                            plano_tipo: planType,
                            fotos: state.plan.photos
                        });
                    }
                } else {
                    if (payload.onError) {
                        payload.onError(response.data.msg);
                    }
                    if (window.location.pathname.length > 1) {
                        dispatch('uiControl/updateStoreQuery', {
                            field:'externalCrash',
                            value: response.data.msg
                        }, { root: true });
                    } else {
                        dispatch('resetApp');
                    }
                    if (!payload.webview) {
                        dispatch('uiControl/requestLogin', {}, { root: true });
                        dispatch('uiControl/noUserRoutines', {}, { root: true });
                    }
                }
            })
            .catch(error => {
                this.$errorPrint('/checkForUser', error);
                if (window.location.pathname.length > 1) {
                    dispatch('uiControl/updateStoreQuery', {
                        field:'externalCrash',
                        value: 'Ocorreu um erro com sua autenticação'
                    }, { root: true });
                } else {
                    dispatch('resetApp');
                }
                dispatch('uiControl/requestLogin', {}, { root: true });
                dispatch('uiControl/noUserRoutines', {}, { root: true });
            });
    },
    checkWebviewUser({ state, dispatch, commit }, payload) {
        Axios.post("/checkForUser", payload)
            .then(response => {
                console.log(response.data.data)
                if (response.data.success) {
                    let userDataArr = Object.entries(response.data.data);
                    userDataArr.forEach((element) => {
                        let payload = {
                            field: element[0],
                            value: element[1]
                        };
                        commit('updateField', payload);
                    });
                    if (
                        state.address &&
                        state.address.address
                    ) {
                        dispatch('uiControl/setJourneyAddress', state.address, { root: true });
                    }
                    this.$api.setToken(response.data.data.bearer)
                    dispatch('fillState');
                    if (payload.onSuccess) {payload.onSuccess();}
                } else {
                    if (payload.onError) {
                        payload.onError(response.data.msg || response.data);
                    }
                }
            })
            .catch(error => {
                if (payload.onError) {
                    payload.onError(error.response.data.msg || error.response.data);
                }
            });
    },
    fillState({ state, dispatch }) {
        dispatch('plans/getPlans', null, { root: true });
        dispatch('photos/lookForCheckedIn', null, { root: true });
        dispatch('checkSuspendRetain');
        dispatch('getPaymentData');
        dispatch('getRaffleData');
        dispatch('getUserVouchers', 1);
    },
    getUserData({ commit, dispatch }) {
        Axios.get('/getuser')
            .then(response => {
                if (response.data.success) {
                    let userDataArr = Object.entries(response.data.data);
                    userDataArr.forEach((element) => {
                        let payload = {
                            field: element[0],
                            value: element[1]
                        };
                        commit('updateField', payload);
                    });
                    if(response.data.data.user_agreements.length > 0) {
                        dispatch('setUserAgreementData', response.data.data.user_agreements);
                    } else {
                        commit('setUserMainAgreement', {})
                    }
                }
            })
            .catch(error => {
                this.$errorPrint('/getuser', error);
            })
    },
    getCookieData({commit}) {
        Axios.get('/getcookies')
          .then(response => {
              response.data.forEach((dataPair) => {
                  commit('updateField', {
                      field: dataPair[0],
                      value: dataPair[1]
                  })
              })
          })
          .catch(error => {
              this.$errorPrint('/getcookies', error);
          })
    },
    checkSuspendRetain({ commit }) {
        Axios.get('/checksuspendretain')
            .then(response => {
                if (response.data.success) {
                    commit('updateField', { field: 'suspend_retain_link', value: response.data.data.url });
                }
            })
            .catch(error => {
                this.$errorPrint('/checksuspendretain', error);
            });
    },
    getPaymentData({ commit }) {
        Axios.get('/getuserpayment')
            .then(response => {
                if (!response.data.error) {
                    commit('updateField', { field: 'payment', value: response.data.data });
                }
            })
            .catch(error => {
                this.$errorPrint('/getuserpayment', error);
            });
    },
    getUserVouchers({ commit }, payload){
        let voucherOptions = {
            page: payload
        };
        Axios.post('/getuservouchers', voucherOptions)
            .then(response => {
                if (!response.data.error) {
                    commit('updateField', {
                        field:'couponVouchers',
                        value:response.data
                    });
                }
            })
            .catch(error => {
                this.$errorPrint('/getuservouchers', error);
            });
    },
    getRaffleData({ commit }) {
        Axios.get('/getuserraffle')
            .then(response => {
                if (!response.data.error) {
                    commit('updateField', { field: 'raffleDate', value: response.data.data.raffleDate });
                }
            })
            .catch(error => {
                this.$errorPrint('/getuserraffle', error);
            });
        Axios.get('/getusernextraffle')
            .then(response => {
                if (!response.data.error) {
                    commit('updateField', { field: 'nextRaffleDate', value: response.data.data.raffleDate });
                }
            })
            .catch(error => {
                this.$errorPrint('/getuserraffle', error);
            });
    },
    loggedInSucessfully({ commit }) {
        commit('uiControl/updateField', { field: 'loggedIn', value: true }, { root: true });
        commit('uiControl/updateField', { field: 'loading', value: false }, { root: true });
    },
    gtmPushEvent({state}, payload) {
        if (gtag) {
            gtag('event', payload.event, payload.eventParams);
        }
    },
    resetApp() {
        window.localStorage.removeItem('uiControl');
        window.localStorage.removeItem('userData');
    },
    logOut({commit, dispatch}, payload) {
        Axios.get('/logout')
            .then(response => {
                if (!response.data.error) {
                    dispatch('persistent/clearUser', {}, {root:true});
                    dispatch('resetApp');
                    commit('updateField', {field: 'bearer', value: null});
                    this.$api.setToken(false);
                    if (payload && payload.query) {
                        location.href = '/'+payload.query;
                    } else {
                        location.reload();
                    }
                }
            })
            .catch(error => {
                this.$errorPrint('/logout', error);
            });
    },
    setUserAgreementData({state, commit}, payload) {
        payload.forEach((agreement) => {
            if (agreement.type === 'jockey') {
                commit('setUserMainAgreement', agreement)
            } else if (
              agreement.type === 'loyalty_plan' &&
              (
                state.user_main_agreement &&
                state.user_main_agreement.type !== 'jockey'
              )
            ) {
                commit('setUserMainAgreement', agreement)
            } else if (!state.user_main_agreement) {
                commit('setUserMainAgreement', agreement)
            }
        })
    }
};

export const mutations = {
    updateField(state, payload) {
        state[payload.field] = payload.value;
    },
    setUserMainAgreement(state, payload) {
        state.user_main_agreement = payload;
    }
};